import errors from '@front/volcanion/classes/error'
import { I18n } from '@front/volcanion'

class Callbacks {
  static onSubmitHandler(order, next_transport_status, updateStatus, payJob, orderCancel, not_loaded_reason_id) {
    return async function onSubmit(values, extra, meta, state) {
      const assigned_transport = order?.assigned_transport

      switch (extra) {
        case 'next':
          return updateStatus({
            transport_id: assigned_transport?.transport_id,
            status: next_transport_status,
            extra: {
              load_count: order?.passenger_count,
              passenger_name: order?.loads?.[0]?.src_contact,
            },
            force: true
          })
        case 'payment':
          return payJob({
            user_id: assigned_transport?.driver,
            job_id: order?.job,
            ...values,
          })
        case 'cancel':
          return orderCancel({
            order_id: order?.order_id,
            reason: not_loaded_reason_id
          })
      }
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(error, values, extra, meta, state) {
      const context = error.getContext()
      const message = (error instanceof errors.MismatchError && context?.data?.message) || I18n.t('error.unknow')
      return [message, { variant: 'error' }]
    }
  }
}

export default Callbacks
