import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import { DialogContent, FormDialog } from '@front/squirtle'

const withForm = Component => (props) => {
  const { order_id, closeDialogOnComplete = true } = props || {}

  const [payJob] = hooks.useModelFunction('job', 'pay')
  const [updateStatus] = hooks.useModelFunction('transport', 'updateStatus')
  const [orderCancel] = hooks.useModelFunction('order', 'cancel')
  const [not_loaded_reason_id] = hooks.useModelSearch('reason', 'get', {
    mutator: reason => reason.reason_id,
    initial_filter: {},
    forced_filter: {
      identifier: "not_loaded"
    },
    mode: 'active',
    single: true,
  })

  const [order] = hooks.useModel('order', [order_id], {
    single: true,
    populate: ['quotation', 'driver_estimation', 'payment_type', 'assigned_transport', 'loads']
  })

  const [next_transport_status] = hooks.useJobNextStatus({ job_id: order?.job })
  const estimation = !!order?.payment_type?.bill_passenger ? order?.quotation : order?.driver_estimation
  const driver_package_fee = estimation?.driver_package_fee
  const option_amount = estimation?.option_amount || 0

  const formProps = {
    initialValues: {
      base: 0,
      fees: 0,
      prepaid: 0,
      option_amount,
      driver_package_fee,
    },
    initialState: {
      order_id,
      closeDialogOnComplete,
    },
    onSubmit: useCallback(Callbacks.onSubmitHandler(order, next_transport_status, updateStatus, payJob, orderCancel, not_loaded_reason_id), [order, next_transport_status, updateStatus, payJob, orderCancel, not_loaded_reason_id]),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
  }

  const mergedProps = {}

  return (
    <FormDialog
      name='simulator'
      allowConfirm={false}
      closeOnSuccess={false}
      formProps={formProps}
    >
      <DialogContent>
        <Component {...mergedProps} />
      </DialogContent>
    </FormDialog>
  )
}

export default withForm
